<template>
  <v-card class="my-6">
    <v-card-title class="align-start">
      <span class="font-weight-semibold text-base">Statistiques</span>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="3" v-for="card in cards" :key="card.title">
          <div class="d-flex">
            <v-avatar size="44" :color="card.color" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ card.icon }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-on="on"
                    v-bind="attrs"
                    class="text--secondary text-sm"
                    >{{ card.title }}</span
                  >
                </template>
                <span>{{ card.hoverTitle }}</span>
              </v-tooltip>
              <br />

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text--primary text-xl font-weight-bold"
                    v-on="on"
                    v-bind="attrs"
                  >
                    {{ card.value }}
                  </span>
                </template>
                <span>{{ card.raw }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiPin,
  mdiNumeric,
  mdiNumeric2BoxMultiple,
  mdiSpeedometer,
  mdiPercent,
  mdiTarget,
  mdiCurrencyEur,
  mdiCashMultiple,
  mdiSackPercent,
  mdiAccount,
  mdiTargetAccount,
} from "@mdi/js";
import {
  formatCurrencyCompact,
  formatCurrency,
  formatNumber,
  formatNumberCompact,
  formatPercent,
} from "@/utils/formatting";

export default {
  name: "GlobalStatsCard",
  props: {
    sites: {
      type: Array,
      default: () => [],
    },
    partnerViews: {
      type: Array,
      default: () => [],
    },
    month: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      icons: {
        mdiCurrencyEur,
        mdiTarget,
        mdiPercent,
        mdiCashMultiple,
        mdiSackPercent,
        mdiSpeedometer,
        mdiAccount,
        mdiTargetAccount,
        mdiNumeric,
        mdiPin,
        mdiNumeric2BoxMultiple,
      },
      cards: [],
    };
  },
  created() {},
  methods: {
    updateCardsData() {
      this.cards = [
        {
          title: "CA global",
          hoverTitle:
            "CA généré avant et après republication des articles ayant été republiés",
          value: formatCurrencyCompact(this.globalData.ca),
          raw: formatCurrency(this.globalData.ca),
          icon: this.icons.mdiCurrencyEur,
          color: "#fb9e0b",
        },
        {
          title: "% CA republication",
          hoverTitle:
            "% du CA réalisé par les articles après leur republication",
          value: formatPercent(this.globalData.ca_repub_percent),
          raw: formatPercent(this.globalData.ca_repub_percent),
          icon: this.icons.mdiPercent,
          color: "#fb9e0b",
        },
        {
          title: "CA avant republication",
          hoverTitle: "CA réalisé avant que les articles aient été publiés",
          value: formatCurrencyCompact(this.globalData.ca_before),
          raw: formatCurrency(this.globalData.ca_before),
          icon: this.icons.mdiCashMultiple,
          color: "#fb9e0b",
        },
        {
          title: "CA après republication",
          hoverTitle: "CA réalisé après que les articles aient été publiés",
          value: formatCurrencyCompact(this.globalData.ca_after),
          raw: formatCurrency(this.globalData.ca_after),
          icon: this.icons.mdiCashMultiple,
          color: "#fb9e0b",
        },
        {
          title: "Sessions globales",
          hoverTitle:
            "Sessions générées avant et après republication des articles ayant été republiés",
          value: formatNumberCompact(this.globalData.sessions),
          raw: formatNumber(this.globalData.sessions),
          icon: this.icons.mdiSpeedometer,
          color: "#444eff",
        },
        {
          title: "% Session republication",
          hoverTitle:
            "% de sessions réalisées par les articles après sa republication",
          value: formatPercent(this.globalData.sessions_repub_percent),
          raw: formatPercent(this.globalData.sessions_repub_percent),
          icon: this.icons.mdiPercent,
          color: "#444eff",
        },
        {
          title: "Sessions avant republication",
          hoverTitle:
            "Sessions réalisées avant que les articles aient été publiés",
          value: formatNumberCompact(this.globalData.sessions_before),
          raw: formatNumber(this.globalData.sessions_before),
          icon: this.icons.mdiSpeedometer,
          color: "#444eff",
        },
        {
          title: "Sessions après republication",
          hoverTitle:
            "Sessions réalisées après que les articles aient été publiés",
          value: formatNumberCompact(this.globalData.sessions_after),
          raw: formatNumber(this.globalData.sessions_after),
          icon: this.icons.mdiSpeedometer,
          color: "#444eff",
        },
      ];
    },
  },
  computed: {
    globalData() {
      return this.$store.getters["gd/getGlobalData"];
    },
  },
  watch: {
    globalData() {
      this.updateCardsData();
    },
  },
};
</script>

<style scoped></style>
